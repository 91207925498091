<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Add Page
        <div class="card-header-actions">
          <a class="card-header-action" href="page" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <b-row>
          <b-col sm="12">
            <b-form @submit.prevent="pagePost" novalidate>
              <b-row>
                <b-col sm="6">
                  <b-form-group id="typeInputGroup1"
                                label="Related Content"
                                label-for="template">
                    <b-form-select id="template"
                                  :plain="true"
                                  v-model.lazy.trim="$v.form.template.$model"
                                  :state="chkState('template')"
                                  :options="[{value: '', text:'Please select'},
                                                    {value: 'simple_page', text: 'Simple Page'}]"
                                  name="form.template"
                                  aria-describedby="templateFeedback"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="templateFeedback" v-for="(error , index) in errors.form.template" :key="index">
                      - {{ error }} <br>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group id="titleInputGroup1"
                                label="Page name (only seen by admins)"
                                label-for="title">
                    <b-form-input id="name"
                                  type="text"
                                  v-model.lazy.trim="$v.form.name.$model"
                                  :state="chkState('name')"
                                  aria-describedby="pageNameFeedback"
                                  placeholder="Please Enter Page Name"
                                  autocomplete='given-name'
                                  name="form.name"
                                  autofocus />
                    <b-form-invalid-feedback id="pageNameFeedback" v-for="(error , index) in errors.form.name" :key="index">
                      - {{ error }} <br>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group id="titleInputGroup1"
                            label="Page Title"
                            label-for="title">
                <b-form-input id="title"
                              type="text"
                              v-model.lazy.trim="$v.form.title.$model"
                              :state="chkState('title')"
                              aria-describedby="titleFeedback"
                              placeholder="Please Enter Page Title"
                              name="form.title"
                              autocomplete='given-name'
                              autofocus />
                <b-form-invalid-feedback id="titleFeedback" >
                  <span v-if="!$v.form.title.required">- Judul halaman tidak boleh kosong!</span>
                  <span v-if="!$v.form.title.isUnique">- Judul halaman tidak boleh sama dengan data sebelumnya!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="titleInputGroup1"
                            label="Page Slug (URL)"
                            label-for="slug">
                <b-form-input id="slug"
                              type="text"
                              v-model.lazy.trim="$v.form.slug.$model"
                              :state="chkState('slug')"
                              aria-describedby="slugFeedback"
                              placeholder="Please Enter Page Slug"
                              name="form.slug"
                              autocomplete='given-name'
                              autofocus />
                <b-form-invalid-feedback id="slugFeedback" >
                  <span v-if="!$v.form.slug.required">- Slug halaman tidak boleh kosong!</span>
                  <span v-if="!$v.form.slug.isUnique">- Slug halaman tidak boleh sama dengan data sebelumnya!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="contentInputGroup2"
                            label="Content"
                            label-for="content">
                <quill-editor id="content"
                              v-model.lazy.trim="$v.form.content.$model"
                              :state="chkState('content')"
                              name="form.content"
                              autocomplete='family-name'>
                </quill-editor>
                <div  v-if="this.form.content == '' " >
                  <p id="contentFeedback" v-for="(error , index) in errors.form.content" :key="index">
                    - {{ error }} <br>
                  </p>
                </div>
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"


export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        title: '',
        content: '',
        name: '',
        slug: '',
        template: '',
      },
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          title: [],
          content: [],
          category: [],
          type: [],
          slug: []
        },
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      title: {
        required,
        isUnique (value) {
          if (value === '') return true

          return new Promise ((resolve, reject) => {
            setTimeout(() => {

              this.$http.post(`page-title/${value}`)
              .then((success) => {
                if (success.data == "") {
                  resolve(true)
                  return true
                }else{
                  reject(false)
                  return false
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.errors.code = error.response.status;
                  this.errors.status = error.response.data.meta.code;
                  this.errors.headers = error.response.headers;
                }
              })
            }, 350 + Math.random() * 300)
          })
        }
      },
      content: {
        required,
      },
      name: {
        required
      },
      template: {
        required
      },
      slug: {
        required,
        isUnique (value) {
          if (value === '') return true

          return new Promise ((resolve, reject) => {
            setTimeout(() => {

              this.$http.get(`page-slug/${value}`)
              .then((success) => {
                if (success.data == "") {
                  resolve(true)
                  return true
                }else{
                  reject(false)
                  return false
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.errors.code = error.response.status;
                  this.errors.status = error.response.data.meta.code;
                  this.errors.headers = error.response.headers;
                }
              })
            }, 350 + Math.random() * 300)
          })
        }
      },
    }
  },
  methods: {
    pagePost() {
      // if (this.validate()) {
      //   this.$nextTick(() => {
        this.validate();

        this.isLoading = true
          this.$http.post(`page-store`, {
              template: this.form.template,
              name: this.form.name,
              title: this.form.title,
              slug: this.form.slug,
              content: this.form.content
          }).then(() => {
            this.isLoading = false
            this.$router.push({name: 'Page'});
            this.$toasted.success('Page successfully created!')
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if(this.errors.status == 422) {
                this.$toasted.error('Please fill out the form that must be required')
                this.errors.message = error.response.data.meta.message;
                this.errors.form.title = this.errors.message.title;
                this.errors.form.content = this.errors.message.content;
                this.errors.form.slug = this.errors.message.slug;
                this.errors.form.name = this.errors.message.name;
                this.errors.form.template = this.errors.message.template;
              }
            }
          })
      //   })
      // }
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.invalid-feedback {
    font-size: 100% !important;
}
#contentFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
